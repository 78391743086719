/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    code: "code",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://nvidia.github.io/cuda-quantum/latest/index.html"
  }, "CUDA Quantum"), " is a library and compiler\ntoolchain for programming hybrid quantum programs on both GPUs and QPUs (quantum processing\nunits). PennyLane programs can be compiled using CUDA Quantum via the\n", React.createElement(_components.a, {
    href: "https://docs.pennylane.ai/en/stable/introduction/compiling_workflows.html"
  }, React.createElement(_components.code, null, "@qml.qjit"), " decorator"), ";\nfor more details, see ", React.createElement(_components.a, {
    href: "../what-is-quantum-just-in-time-qjit-compilation/"
  }, "What is QJIT?"), "."), "\n", React.createElement(_components.p, null, "CUDA Quantum consists of the following components:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "A C++ frontend for defining a user's quantum program workflow,"), "\n", React.createElement(_components.li, null, "A set of Python bindings to the C++ API,"), "\n", React.createElement(_components.li, null, "the ", React.createElement(_components.code, null, "nvq++"), " compiler, that compiles the program down to an executable, and"), "\n", React.createElement(_components.li, null, "various simulator and hardware backends for executing the quantum components of the workflow.\nSupported simulators include ", React.createElement(_components.a, {
    href: "../what-is-cuquantum"
  }, "cuQuantum"), " and ", React.createElement(_components.a, {
    href: "https://github.com/softwareqinc/qpp"
  }, "q++"), "."), "\n"), "\n", React.createElement(_components.p, null, "Internally, CUDA Quantum levarages technologies such as ", React.createElement(_components.a, {
    href: "https://mlir.llvm.org/"
  }, "MLIR"), ",\n", React.createElement(_components.a, {
    href: "https://llvm.org/"
  }, "LLVM"), ", and ", React.createElement(_components.a, {
    href: "https://www.qir-alliance.org/"
  }, "QIR"), " as intermediate representations of\nthe hybrid workflow, similar to other hybrid compilers such as ", React.createElement(_components.a, {
    href: "https://github.com/pennylaneai/catalyst"
  }, "Catalyst"), "."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
